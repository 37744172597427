<template>
  <div id="app">
    
    <Header />
  </div>
</template>

<script>
import Header from './components/Header.vue'

import { scriptInsert } from './js/tools'

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return{
      baidu:'https://hm.baidu.com/hm.js?bea2135b33bb79fd4fb1fa42b3fbde93',
      googleTag:'G-1QJDX7Q7D1',
      googleSiteTag:'UA-239281962-1',
      googleGTM:'GTM-PQK3V5H',
    }
  },
  methods: {
		gtag() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(arguments);
		}
	},
  mounted() {
	// 百度统计
    if(this.baidu) {
      scriptInsert(this.baidu);
    }
    // Google tag (gtag.js)
		if (this.googleTag) {
			scriptInsert(`https://www.googletagmanager.com/gtag/js?id=${this.googleTag}`).then(
				() => {
					this.gtag('js', new Date());
					this.gtag('config', this.googleTag);
				}
			);
		}
    // Global site tag (gtag.js)
    if (this.googleSiteTag) {
			scriptInsert(`https://www.googletagmanager.com/gtag/js?id=${this.googleSiteTag}`).then(
				() => {
					this.gtag('js', new Date());
					this.gtag('config', this.googleSiteTag);
				}
			);
		}
    // Google Tag Manager
		if (this.googleGTM) {
			scriptInsert(`https://www.googletagmanager.com/gtm.js?id=${this.googleGTM}`).then(
				() => {
					this.gtag('js', new Date());
					this.gtag('config', this.googleGTM);
				}
			);
		}
		
	}

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
}
</style>
