import Vue from 'vue'
import 'lib-flexible/flexible'
import App from './App.vue'
import './assets/reset.css'  // 引入基本样式
import VueLazyLoad from 'vue-lazyload'

import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyA6WVwiroxBDgCsJJGhecQW3hXGsCOw3pw",
  authDomain: "jhy-abtest.firebaseapp.com",
  projectId: "jhy-abtest",
  storageBucket: "jhy-abtest.appspot.com",
  messagingSenderId: "600037489493",
  appId: "1:600037489493:web:173dec2feccede9137cddc",
  measurementId: "G-TXC2Z8GLLS"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$analytics = firebase.analytics();


// Vue.use(VueLazyLoad)
Vue.use(VueLazyLoad,{
  preLoad:1.3,
  loading: require('./assets/img/loading.gif'),
  error: require('./assets/img/404.jpg'),
  attempt:2,
})
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
