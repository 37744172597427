export default [
  {name: '果酱视频',data:{androidApp:"https://shc03.61d5948.com/r/URo",iosApp:"https://gjtv9.vip"}},
  {name: '嘿嘿连载',data:{androidApp:"https://shc03.61d5948.com/r/Ado",iosApp:"https://heihei99.app"}},
  {name: 'CiliCili短视频',data:{androidApp:"https://shc03.61d5948.com/r/KRo",iosApp:"https://cili9.vip"}},
  {name: '久草视频',data:{androidApp:"https://shc03.61d5948.com/r/ewo",iosApp:"https://czhan9.app"}},
  {name: '果酱短视频',data:{androidApp:"https://shc03.61d5948.com/r/URo",iosApp:"https://cili9.vip"}},
  {name: 'H动漫',data:{androidApp:"https://shc03.61d5948.com/r/Bdo",iosApp:"https://byym53.com/"}},
  {name: '香蕉视频',data:{androidApp:"https://shc03.61d5948.com/r/Mwo",iosApp:"https://cxj11.app"}},
  {name: '王者荣耀短视频',data:{androidApp:"https://shc03.61d5948.com/r/zRo",iosApp:"https://cili9.vip"}},
  {name: '肉漫社',data:{androidApp:"https://shc03.61d5948.com/r/UBo",iosApp:"https://byym53.com/"}},
  {name: '小笛视频',data:{androidApp:"https://shc03.61d5948.com/r/Gwo",iosApp:"https://xdtv9.app"}},
  {name: '国产富二代',data:{androidApp:"https://shc03.61d5948.com/r/5wo",iosApp:"https://xdtv9.app"}},
  {name: 'H成人小说',data:{androidApp:"https://shc03.61d5948.com/r/zBo",iosApp:"https://heihei99.app"}},
  {name: '草莓视频',data:{androidApp:"https://shc03.61d5948.com/r/1oo",iosApp:"https://cmg99.app"}},
  {name: '幼女视频',data:{androidApp:"https://shc03.61d5948.com/r/joo",iosApp:"https://gjtv9.vip"}},
  {name: '内射视频',data:{androidApp:"https://shc03.61d5948.com/r/bwo",iosApp:"https://xdtv9.app"}},
  {name: '麻豆视频',data:{androidApp:"https://shc03.61d5948.com/r/9oo",iosApp:"https://cxj11.app"}},
  {name: '逼站暗网免費看',data:{androidApp:"https://shc03.61d5948.com/r/Ywo",iosApp:"https://gjtv9.vip"}},
  {name: '芒果视频',data:{androidApp:"https://shc03.61d5948.com/r/Lwo",iosApp:"https://cmg99.app"}},
  {name: '色中色',data:{androidApp:"https://shc03.61d5948.com/r/3wo",iosApp:"https://cmg99.app"}},
  {name: '麻豆破解版',data:{androidApp:"https://shc03.61d5948.com/r/Zoo",iosApp:"https://xdtv9.app"}},
  {name: 'JAV日本高清无码',data:{androidApp:"https://shc03.61d5948.com/r/qoo",iosApp:"https://cxj11.app"}},
  {name: '中学生视频',data:{androidApp:"https://shc03.61d5948.com/r/noo",iosApp:"https://cmg99.app"}},
  {name: '无码高清',data:{androidApp:"https://shc03.61d5948.com/r/1wo",iosApp:"https://czhan9.app"}},
  {name: '樱桃视频',data:{androidApp:"https://shc03.61d5948.com/r/jwo",iosApp:"https://cxj11.app"}},
  {name: '强奸视频',data:{androidApp:"https://shc03.61d5948.com/r/VRo",iosApp:"https://cmg99.app"}},
  {name: '免费AV视频',data:{androidApp:"https://shc03.61d5948.com/r/poo",iosApp:"https://cxj11.app"}},
  {name: '奶茶视频',data:{androidApp:"https://shc03.61d5948.com/r/Wwo",iosApp:"https://czhan9.app"}},
  {name: 'CiliCili视频',data:{androidApp:"https://shc03.61d5948.com/r/4Ro",iosApp:"https://xdtv9.app"}},
  {name: '萝卜视频',data:{androidApp:"https://shc03.61d5948.com/r/ywo",iosApp:"https://gjtv9.vip"}},
  {name: '中文成人娱乐网',data:{androidApp:"https://shc03.61d5948.com/r/oAo",iosApp:"https://c55.app"}},
  {name: '久草短视频',data:{androidApp:"https://shc03.61d5948.com/r/ERo",iosApp:"https://cili9.vip"}},
  {name: '快手成人版',data:{androidApp:"https://shc03.61d5948.com/r/7Ro",iosApp:"https://cili9.vip"}},
  {name: 'cos短视频',data:{androidApp:"https://shc03.61d5948.com/r/vRo",iosApp:"https://cili9.vip"}},
  {name: '抖音短视频',data:{androidApp:"https://shc03.61d5948.com/r/wRo",iosApp:"https://cili9.vip"}},
  {name: '免费短视频',data:{androidApp:"https://shc03.61d5948.com/r/dRo",iosApp:"https://cili9.vip"}},
  {name: '3d短视频',data:{androidApp:"https://shc03.61d5948.com/r/hRo",iosApp:"https://cili9.vip"}},
  {name: '初中生短视频',data:{androidApp:"https://shc03.61d5948.com/r/RRo",iosApp:"https://cili9.vip"}},
  {name: '处女私拍',data:{androidApp:"https://shc03.61d5948.com/r/HRo",iosApp:"https://cili9.vip"}},
  {name: '小学生短视频',data:{androidApp:"https://shc03.61d5948.com/r/cRo",iosApp:"https://cili9.vip"}},
  {name: '幼女短视频',data:{androidApp:"https://shc03.61d5948.com/r/kRo",iosApp:"https://cili9.vip"}},
  {name: '孕妇短视频',data:{androidApp:"https://shc03.61d5948.com/r/tRo",iosApp:"https://cili9.vip"}},
  {name: '妈妈短视频',data:{androidApp:"https://shc03.61d5948.com/r/QRo",iosApp:"https://cili9.vip"}},
  {name: '学生短视频',data:{androidApp:"https://shc03.61d5948.com/r/rRo",iosApp:"https://cili9.vip"}},
  {name: '内射短视频',data:{androidApp:"https://shc03.61d5948.com/r/NRo",iosApp:"https://cili9.vip"}},
  {name: '人兽短视频',data:{androidApp:"https://shc03.61d5948.com/r/SRo",iosApp:"https://cili9.vip"}},
  {name: '18禁漫',data:{androidApp:"https://shc03.61d5948.com/r/EBo",iosApp:"https://byym53.com/"}},
  {name: '最全污漫',data:{androidApp:"https://shc03.61d5948.com/r/2do",iosApp:"https://heihei99.app"}},
  {name: '91學妹禁漫',data:{androidApp:"https://shc03.61d5948.com/r/Fdo",iosApp:"https://byym53.com/"}},
  {name: '情色卡通',data:{androidApp:"https://shc03.61d5948.com/r/wBo",iosApp:"https://byym53.com/"}},
  {name: '色情书库',data:{androidApp:"https://shc03.61d5948.com/r/Xdo",iosApp:"https://heihei99.app"}},
  {name: '萝莉社',data:{androidApp:"https://shc03.61d5948.com/r/hBo",iosApp:"https://heihei99.app"}},
  {name: '真人H直播',data:{androidApp:"https://shc03.61d5948.com/r/AAo",iosApp:"https://cxj11.app"}},
  {name: '同城约炮平台',data:{androidApp:"https://shc03.61d5948.com/r/iwo",iosApp:"https://gjtv9.vip"}},
  {name: '真人H聊天室',data:{androidApp:"https://shc03.61d5948.com/r/Cwo",iosApp:"https://czhan9.app"}},
  {name: '熟女俱乐部',data:{androidApp:"https://shc03.61d5948.com/r/Zwo",iosApp:"https://gjtv9.vip"}}
]
