export function scriptInsert(url, sucess, failed) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.onload = () => {
            if (sucess) sucess();
            resolve(true);
        };
        script.onerror = (err) => {
            if (failed) failed();
            reject(err);
        };
        document.getElementsByTagName('head')[0].appendChild(script)
    })
}
